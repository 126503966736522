import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import BackupTable from 'components/Web_User_Interface/720p_Series/Software/Backup/backupTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Software Menu // Backup",
  "path": "/Web_User_Interface/720p_Series/Software/Backup/",
  "dateChanged": "2017-12-10",
  "author": "Mike Polinowski",
  "excerpt": "You can backup your camera configuration after setting everything up. Download the file and name it appropriately for a later restore. Please be aware that software updates sometimes add new variables / functions to the camera and you won´t be able to use your old backup files anymore. Always create a new backup after a software update to be sure.",
  "image": "./WebUI_720p_SearchThumb_Software_Backup.png",
  "social": "/images/Search/WebUI_720p_SearchThumb_Software_Backup.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "720p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Software Menu // Backup' dateChanged='2017-12-10' author='Mike Polinowski' tag='INSTAR IP Camera' description='You can backup your camera configuration after setting everything up. Download the file and name it appropriately for a later restore. Please be aware that software updates sometimes add new variables / functions to the camera and you won´t be able to use your old backup files anymore. Always create a new backup after a software update to be sure.' image='/images/Search/WebUI_720p_SearchThumb_Software_Backup.png' twitter='/images/Search/WebUI_720p_SearchThumb_Software_Backup.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/720p_Serie/Software/Sicherung/' locationFR='/fr/Web_User_Interface/720p_Series/Software/Backup/' crumbLabel="Backup" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "720p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#720p-web-user-interface",
        "aria-label": "720p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`720p Web User Interface`}</h1>
    <h2 {...{
      "id": "software-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#software-menu",
        "aria-label": "software menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software Menu`}</h2>
    <h3 {...{
      "id": "software-backup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software-backup",
        "aria-label": "software backup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software Backup`}</h3>
    <EuiSpacer mdxType="EuiSpacer" />
    <blockquote>
      <p parentName="blockquote">{`You can backup your camera configuration after setting everything up. Download the file and name it appropriately for a later restore. Please be aware that software updates sometimes add new variables / functions to the camera and you won´t be able to use your old backup files anymore. Always create a new backup after a software update to be sure.`}</p>
    </blockquote>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "716px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c589b5c281b898d0876168f6135c8a24/6bbf7/WebUI-Software_Backup.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "104.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAAsTAAALEwEAmpwYAAACpklEQVQ4y53Ta2+aUBgHcD7Ytk+x7d3Sfp9tSdu0XoqiVtfLUhW1qxsXBYQi4mVubb0A3iciiEDTqEmTLqgzLl26db+cFwfCk//z5ByAKAy/fPX62fMXbzY2QNDjcrogyO90uR0O574bhCA/CHq8kG9v17G36wA93kDgAIJ8DocTgnzAeTLpcrnevnu/D3qSSYQkaYLIkGSGIDLpNJlKLReOExiWXuwpiolE4M3NTeAgGIQg6OTjSTyeEIX65eX3YqnE2bKKMrAs0zQNwzDMOXtnjBePOzs7wLdymWEuBEG4vr6u1+uKovR+2LrdrizLyp/0+/2bmxuSJIFyuQxHbRzH9Xq9XrdXqVRrtbokioPBwA56QFVVy7IQBAHINAmCoNfrRRDEDux0K5VKrVoTBEGW5fF4rD+gKIqu6xiGASiGnic/8zxP07QoiqZpjkYj/VGapk2nUxRFgUAgsL29fXoajsVikiTJcr9QLIii0G63qrWaKAjVanWgKOstaJo2mUzs4mDwwO3eDwaDkUhUkiTLMlVV1TRt9el4PDZ+p+v6bDazi4+Pj/1+PwzD0Sg8T5abzWZ7jTjXWiNJ0nLmUCjk8/nOPp0t2r66uuI4Lv8Lz/MIimAYll/Dsmyz2UylUkAMhg8/HB4dHSUSiUajYZqmpmmjNYvjWX+jqupy5ny+gOM4TdMMQzcaDcuyFnOurCZfGY1Gy2KCIL7MEQTRbrf/8ahWyTyCICRJsizb6XQMw3hCMcMwKIqm02mapp9cXCwWaZrO5XLZbPbJbTPMBYZh/5tcKOVz+VLp6wXLtlotXdcXN+wRw+Hw9vbW/qsKhUIuxxeLJYqihkPl7u5uOp3OHjWZTO7v7zOZDJChKBzHUymcpKh4PB4OhyN/Ew6HE4nE1tbWT/EHHJvtwmjsAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c589b5c281b898d0876168f6135c8a24/e4706/WebUI-Software_Backup.avif 230w", "/en/static/c589b5c281b898d0876168f6135c8a24/d1af7/WebUI-Software_Backup.avif 460w", "/en/static/c589b5c281b898d0876168f6135c8a24/04ac8/WebUI-Software_Backup.avif 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c589b5c281b898d0876168f6135c8a24/a0b58/WebUI-Software_Backup.webp 230w", "/en/static/c589b5c281b898d0876168f6135c8a24/bc10c/WebUI-Software_Backup.webp 460w", "/en/static/c589b5c281b898d0876168f6135c8a24/d8378/WebUI-Software_Backup.webp 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c589b5c281b898d0876168f6135c8a24/81c8e/WebUI-Software_Backup.png 230w", "/en/static/c589b5c281b898d0876168f6135c8a24/08a84/WebUI-Software_Backup.png 460w", "/en/static/c589b5c281b898d0876168f6135c8a24/6bbf7/WebUI-Software_Backup.png 716w"],
              "sizes": "(max-width: 716px) 100vw, 716px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c589b5c281b898d0876168f6135c8a24/6bbf7/WebUI-Software_Backup.png",
              "alt": "Web User Interface - 720p Series - Software Backup",
              "title": "Web User Interface - 720p Series - Software Backup",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`You can backup your camera configuration after setting everything up. Download the file and name it appropriately for a later restore. Please be aware that software updates sometimes add new variables / functions to the camera and you won´t be able to use your old backup files anymore. Always create a new backup after a software update to be sure.`}</p>
    </blockquote>
    <EuiSpacer mdxType="EuiSpacer" />
    <BackupTable mdxType="BackupTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      